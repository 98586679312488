import React, { useContext } from "react";
import { MediaMatchType } from "services/MediaQuery";

const defaultValue = Symbol('This is the default value of Counter Context');
type DefaultValue = symbol;

export interface IMediaQueryContext {
    mediaQueryType: MediaMatchType;
    update: () => void;
    checkMobile: () => boolean;
    checkTablet: () => boolean;
    checkDesktop: () => boolean;
    checkDesktopLarge: () => boolean;
    width: number;
}

const MediaQueryContext = React.createContext<DefaultValue | IMediaQueryContext>(defaultValue);

export default MediaQueryContext;

export const useMediaQueryContext = (): IMediaQueryContext => {
    const value = useContext(MediaQueryContext);
    if (value === defaultValue) {
        throw new Error('Please, initialize the Media Query Context');
    }
    return value as IMediaQueryContext;
}
