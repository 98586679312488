import { ServiceCardProps } from 'models/UI/Services/ServiceCard/ServiceCardProps';
import classes from './ServiceCard.module.scss';

const ServiceCard: React.FC<ServiceCardProps> = ({id, title, description, image}) => {
    return (
        <div id={id} className={classes['service-card']}>
            <img src={image} alt={title} loading="lazy" />
            <h4>{title}</h4>
            <p>{description}</p>
        </div>
    )
}

export default ServiceCard;