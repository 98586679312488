import classes from "./FactCard.module.scss";
import { FactCardProps } from "models/UI/Facts/FactCard/FactCardProps";

const FactCard: React.FC<FactCardProps> = ({
  title,
  description,
  image,
}) => {
  return (
    <div className={classes['fact-card-wrapper']}>
      <div className={classes["fact-card"]}>
        <h3>{title}</h3>
        <p>{description}</p>
        <img src={image} alt="Trofeo" loading="lazy" />
      </div>
    </div>
  );
};

export default FactCard;
