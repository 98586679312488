import classes from "./Footer.module.scss";
import logo from "assets/images/solvetech_logo.svg";
import workana from "assets/images/RRSS/workana.webp";
import { MENU_OPTIONS_COLLECTION } from "collections/MenuOptions.collection";
import { SERVICES_COLLECTION } from "collections/Services.collection";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <article className={classes.footer}>
      <section>
        <img src={logo} alt="logo" loading="lazy" />
        <p>{t("footer.description")}</p>
        <div className={classes["footer__RRSS"]}>
          <a
            href="https://www.workana.com/freelancer/9c7f909f492d5d9663183f5c7d095940"
            title="URL Perfil Workana"
            target="_blank"
            rel="noreferrer"
          >
            <img src={workana} alt="Workana" loading="lazy" />
          </a>
        </div>
      </section>
      <div className={classes["footer__wrapper-links"]}>
        <section className={classes.links}>
          <h4>{t("footer.ourCompany")}</h4>
          <a href="#home" title={t("header.home")}>
            {t("header.home")}
          </a>
          {MENU_OPTIONS_COLLECTION.map((item, index) => {
            const { href, title } = item;
            const titleTranslated = t(title as unknown as TemplateStringsArray);
            return (
              <a key={index} href={href} title={titleTranslated}>
                {titleTranslated}
              </a>
            );
          })}
        </section>
        <section className={classes.links}>
          <h4>{t("services.title")}</h4>
          {
            SERVICES_COLLECTION.map(({id, title}) => {
              const key = `footer${title}`;
                return <a key={key} href={`#${id}`}>{t(title as unknown as TemplateStringsArray)}</a>
            })
          }
        </section>
      </div>
      <br />
    </article>
  );
};

export default Footer;
