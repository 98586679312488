import { MENU_OPTIONS_COLLECTION } from "collections/MenuOptions.collection";
import { useTranslation } from "react-i18next";
import classes from "./HeaderOptions.module.scss";

const HeaderOptions = () => {
  const { t } = useTranslation();
  return (
    <nav className={classes["header-options"]}>
      <ul>
        {MENU_OPTIONS_COLLECTION.map((option) => {
          const { title, href } = option;
          return (
            <li key={href}>
              <a href={href} title={title}>
                {t(title as unknown as TemplateStringsArray)}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default HeaderOptions;
