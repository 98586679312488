export const size = {
    mobile: '320px',
    tablet: '640px',
    desktop: '940px',
    desktopLarge: '1600px'
}
export const device = {
    mobile: `(min-width: ${size.mobile}) and (max-width: ${size.tablet})`,
    minWidthTablet: `(min-width: ${size.tablet})`,
    tablet: `(min-width: ${size.tablet}) and (max-width: ${size.desktop})`,
    minWidthDesktop: `(min-width: ${size.desktop})`,
    desktop: `(min-width: ${size.desktop}) and (max-width: ${size.desktopLarge})`,
    desktopLarge: `(min-width: ${size.desktopLarge})`
};