import logo from "assets/images/solvetech_logo.svg";
import { useMediaQueryContext } from "contexts/MediaQueryContext";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import HeaderOptions from "../HeaderOptions/HeaderOptions";
import classes from './Header.module.scss';

const Header = () => {

  const mediaQueryContext = useMediaQueryContext();
  const isDesktopMode = mediaQueryContext.checkDesktop() || mediaQueryContext.checkDesktopLarge();

  return (
    <header className={classes.header} id="home">
      <img className={classes['header__logo']} src={logo} alt="Logo Solvetech" loading="lazy" />      
      { isDesktopMode ? <HeaderOptions/> : <HamburgerMenu /> }
    </header>
  );
};

export default Header;
