import aws from 'assets/images/technologies/aws.svg';
import azure from 'assets/images/technologies/azure.svg';
import cloudflare from 'assets/images/technologies/cloudflare.svg';
import digitalOcean from 'assets/images/technologies/digitalocean.svg';
import docker from 'assets/images/technologies/docker.svg';
import googleCloud from 'assets/images/technologies/googlecloud.svg';
import kubernetes from 'assets/images/technologies/kubernetes.svg';
import terraform from 'assets/images/technologies/terraform.svg';
import { TechnologyProp } from "models/UI/Technologies/TechnologyProp";


export const TECHNOLOGY_ITEMS: TechnologyProp[] = [
    {
        title: 'AWS',
        image: aws
    },
    {
        title: 'Azure',
        image: azure
    },
    {
        title: 'Google Cloud',
        image: googleCloud
    },
    {
        title: 'Kubernetes',
        image: kubernetes
    },
    {
        title: 'Digital Ocean',
        image: digitalOcean
    },
    {
        title: 'CloudFlare',
        image: cloudflare
    },
    {
        title: 'Terraform',
        image: terraform
    },
    {
        title: 'Docker',
        image: docker
    }
];