import { SERVICES_COLLECTION } from "collections/Services.collection";
import { useTranslation } from "react-i18next";
import ServiceCard from "./ServiceCard/ServiceCard";
import classes from "./Services.module.scss";

const Services = () => {
  const { t } = useTranslation();
  return (
    <article id="services" className={classes.services}>
      <h3 className={classes["services__title"]}>{t('services.title')}</h3>
      <h3 className={classes["services__subtitle"]} dangerouslySetInnerHTML={{
        __html: t('services.description')
      }}></h3>
      <section className={classes["services__cards"]}>
        {SERVICES_COLLECTION.map((service) => {
          const { id, title, description, image } = service;
          return (
            <ServiceCard
              id={id}
              key={title}
              title={t(title as unknown as TemplateStringsArray)}
              description={t(description as unknown as TemplateStringsArray)}
              image={image}
            />
          );
        })}
      </section>
    </article>
  );
};

export default Services;
