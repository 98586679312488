import { TESTIMONIALS_COLLECTION } from "collections/Testimonials.collection";
import { useMediaQueryContext } from "contexts/MediaQueryContext";
import { motion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import TestimonialCard from "./TestimonialCard/TestimonialCard";
import classes from "./Testimonials.module.scss";
import { useTranslation } from "react-i18next";

const Testimonials = () => {

  const {t} = useTranslation();
  const [index, setIndex] = useState<number>(1);
  const mediaQuery = useMediaQueryContext();
  const isDesktop = mediaQuery.checkDesktop() || mediaQuery.checkDesktopLarge();
  const isMobile = mediaQuery.checkMobile();

  const increment = useMemo(() => {
    if(isMobile) {
      return TESTIMONIALS_COLLECTION.length;
    } else if(isDesktop) {
      return 3;
    } else {
      return 2;
    }
  }, [isDesktop, isMobile]);

  const pageVariants = useMemo(
    () => ({
      initial: {
        opacity: 1,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    }),
    []
  );

  const pageTransition = useMemo(
    () => ({
      type: "spring",
      ease: "linear",
      duration: 0.9,
    }),
    []
  );

  const handleClickShowMore = useCallback(() => {
    setIndex((prevState) => {
      return (prevState + 1);
    });
  }, []);

  return (
    <article id="testimonials" className={classes.testimonials}>
      <h3 className={classes["testimonials__title"]}>{t('testimonials.title')}</h3>
      <motion.section
        className={classes["testimonials__cards"]}
        style={{ height: "100%" }}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        {TESTIMONIALS_COLLECTION.slice(0, (index * increment)).map(
          (testimonial, index) => {
            return <TestimonialCard key={index} {...testimonial} />;
          }
        )}
      </motion.section>
      <button onClick={handleClickShowMore}>{t('testimonials.showMore')}</button>
    </article>
  );
};

export default Testimonials;
