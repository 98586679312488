import { FACTS_COLLECTION } from "collections/Facts.collection";
import ServiceCard from "./FactCard/FactCard";
import classes from "./Facts.module.scss";
import { useTranslation } from "react-i18next";

const Facts = () => {
  const {t} = useTranslation();
  return (
    <article className={classes.facts}>
      {FACTS_COLLECTION.map((card) => {
        const { title, description, image } = card;
        return (
          <ServiceCard
            key={title}
            title={title}
            description={t([description as unknown as TemplateStringsArray])}
            image={image}
          />
        );
      })}
    </article>
  );
};

export default Facts;
