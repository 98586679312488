import MediaQueryContext from "contexts/MediaQueryContext";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { MediaMatchType, MediaQuery } from "services/MediaQuery";

const MediaQueryContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const mediaQuery = useMemo(() => new MediaQuery(), []);
    const [mediaQueryType, setMediaQueryType] = useState<MediaMatchType>(mediaQuery.getMediaQueryType());
    const [width, setWidth] = useState<number>(mediaQuery.getWidth());
 
    const update = useCallback(() => {
        mediaQuery.update();
        setMediaQueryType(mediaQuery.getMediaQueryType());
        setWidth(mediaQuery.getWidth());
    }, [mediaQuery]);

    const checkMobile = useCallback(() => {
        return mediaQuery.checkMobile();
    }, [mediaQuery]);

    const checkTablet = useCallback(() => {
        return mediaQuery.checkTablet();
    }, [mediaQuery]);

    const checkDesktop = useCallback(() => {
        return mediaQuery.checkDesktop();
    }, [mediaQuery]);

    const checkDesktopLarge = useCallback(() => {
        return mediaQuery.checkDesktopLarge();
    }, [mediaQuery]);
    
    const value = useMemo(() => {
        return {
            mediaQueryType,
            update,
            checkMobile,
            checkTablet,
            checkDesktop,
            checkDesktopLarge,
            width
        }
    }, [checkDesktop, checkDesktopLarge, checkMobile, checkTablet, width, mediaQueryType, update]);

    useEffect(() => {
        window.addEventListener('resize', update);
        return () => {
            window.removeEventListener('resize', update);
        };
    }, [update]);

    return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>;
}

export default MediaQueryContextProvider;