import server from "assets/images/server.svg";
import { useTranslation } from "react-i18next";
import classes from "./Introduction.module.scss";

const Introduction = () => {

  const { t } = useTranslation();
  
  return (
    <article className={classes.introduction}>
      <section className={classes['introduction__card']}>
        <h2>{t('home.title')}</h2>
        <p>{t('home.description')}</p>
        <a href="https://wa.me/15188931271" 
           title="Let's chat" 
           target="_blank"
           rel="noreferrer">{t('home.callToAction')}</a>
      </section>
      <img className={classes['introduction__server-image']} src={server} alt="server" loading="lazy"  />
    </article>
  );
};

export default Introduction;
