import { FactCardProps } from "models/UI/Facts/FactCard/FactCardProps";
import trophy from "assets/images/trophy.svg";
import world from "assets/images/world.svg";
import check from "assets/images/check.svg";

export const FACTS_COLLECTION: FactCardProps[] = [
    {
      title: "#1",
      description: "facts.first",
      image: trophy,
    },
    {
      title: "50+",
      description: "facts.second",
      image: world,
    },
    {
      title: "200+",
      description: "facts.third",
      image: check,
    },
  ];
  