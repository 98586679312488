import { HamburgerButtonProps } from 'models/UI/HamburgerMenu/HamburgerButtonProps';
import classes from './HamburgerButton.module.scss';
import { useState, useCallback, useEffect, memo } from 'react';

const HamburgerButton: React.FC<HamburgerButtonProps> = ({ opened: initialValue, onClick }) => {

  console.log('HamburgerButton');

  const [opened, setOpened] = useState<boolean>(() => initialValue);

  const handleClick = useCallback(() => {
    setOpened((prevState) => {
      const newValue = !prevState;      
      onClick(newValue);
      return newValue;
    });
  }, [onClick]);

  useEffect(() => {
      if (initialValue !== opened) {
        setOpened(initialValue);
      }
  }, [initialValue, opened])

  const classToAdd = [
    classes['hamburger-btn'],
    opened ? classes['open'] : '',
  ].join(' ');

  return (
    <button className={classToAdd} onClick={handleClick}>
      <div>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  );
};

export default memo(HamburgerButton);
