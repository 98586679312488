import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';
import translationES from './es/translation.json';

export const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    }
};

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        lng: navigator.language,
        debug: true,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });
    
export default i18next;
