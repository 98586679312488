import { MenuOptions } from "models/UI/Header/MenuOptions";

export const MENU_OPTIONS_COLLECTION: MenuOptions[] = [
    {
        title: 'header.services',
        href: '#services'
    },
    {
        title: 'header.technologies',
        href: '#technologies'
    },
    {
        title: 'header.testimonials',
        href: '#testimonials'
    },
    {
        title: 'header.contact',
        href: '#contactus'
    }
]