import { TECHNOLOGY_ITEMS } from 'collections/Technologies.collection';
import classes from './Technologies.module.scss';
import TechnologyImage from './TechnologyImage/TechnologyImage';
import { useTranslation } from 'react-i18next';

const Technologies = () => {
    const {t} = useTranslation();
    
    return <article id='technologies' className={classes.technologies}>
        <h3 className={classes['technologies__title']}>{t('technologies.title')}</h3>
        <h4 className={classes['technologies__subtitle']}>{t('technologies.description')}</h4>
        <section className={classes['technologies__images']}>
            {TECHNOLOGY_ITEMS.map(technology => {
                const {title, image} = technology;
                return <TechnologyImage key={title} title={title} image={image} />
            })}
        </section>
    </article>
}

export default Technologies;