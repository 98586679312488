import { TestimonialCardProps } from "models/UI/Testimonials/TestimonialCard/TestimonialCardProps";
import dendro from 'assets/images/companies/dendro.png';
import bicom from 'assets/images/companies/bicom.png';
import pagocash from 'assets/images/companies/pagocash.png';

export const TESTIMONIALS_COLLECTION: TestimonialCardProps[] = [
    {
        avatar: dendro,
        fullname: 'testimonials.list.first.name',
        companyRole: 'testimonials.list.first.companyRole',
        projectName: 'testimonials.list.first.projectName',
        review: 'testimonials.list.first.testimonial'
    },
    {
        avatar: bicom,
        fullname: 'testimonials.list.second.name',
        companyRole: 'testimonials.list.second.companyRole',
        projectName: 'testimonials.list.second.projectName',
        review: 'testimonials.list.second.testimonial'    
    },
    {
        avatar: pagocash,
        fullname: 'testimonials.list.third.name',
        companyRole: 'testimonials.list.third.companyRole',
        projectName: 'testimonials.list.third.projectName',
        review: 'testimonials.list.third.testimonial'
    }
];