import { TestimonialCardProps } from 'models/UI/Testimonials/TestimonialCard/TestimonialCardProps';
import classes from './TestimonialCard.module.scss';
import { useTranslation } from 'react-i18next';

const TestimonialCard: React.FC<TestimonialCardProps> = ({avatar, fullname, companyRole, projectName, review}) => {
    const { t } = useTranslation();
    return <article className={classes['testimonial-card']}>
        <section className={classes['testimonial-card__header']}>
            <img src={avatar} alt='imagen' loading='lazy' />
            <div className={classes['testimonial-card__text']}>
                <h4>{t(fullname as unknown as TemplateStringsArray)}</h4>
                <h5>{t(companyRole as unknown as TemplateStringsArray)}</h5>
                <span>{t(projectName as unknown as TemplateStringsArray)}</span>
            </div>
        </section>
        <p>{t(review as unknown as TemplateStringsArray)}</p>
    </article>
};

export default TestimonialCard;