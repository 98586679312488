import { ServiceCardProps } from "models/UI/Services/ServiceCard/ServiceCardProps";
import consulting from 'assets/images/services/consulting.svg';
import appPlatform from 'assets/images/services/app-platform.svg';
import managedHosting from 'assets/images/services/managed-hosting.svg';
import storage from 'assets/images/services/storage.svg';
import virtualMachines from 'assets/images/services/virtual-machines.svg';
import cloudContainers from 'assets/images/services/cloud-containers.svg';

export const SERVICES_COLLECTION: ServiceCardProps[] = [
    {
        id: 'costOptimization',
        title: 'services.costOptimizationTitle',
        description: 'services.costOptimizationDescription',
        image: consulting
    },
    {
        id: 'solutionsDesign',
        title: 'services.solutionsDesignTitle',
        description: 'services.solutionsDesignDescription',
        image: appPlatform
    },
    {
        id: 'monitoringImplementation',
        title: 'services.monitoringImplementationTitle',
        description: 'services.monitoringImplementationDescription',
        image: managedHosting
    },
    {
        id: 'consultingTitle',
        title: 'services.consultingTitle',
        description: 'services.consultingDescription',
        image: storage
    },
    {
        id: 'platformMigration',
        title: 'services.platformMigrationTitle',
        description: 'services.platformMigrationDescription',
        image: virtualMachines
    },
    {
        id: 'infrastructureDeployment',
        title: 'services.infrastructureDeploymentTitle',
        description: 'services.infrastructureDeploymentDescription',
        image: cloudContainers
    }
];