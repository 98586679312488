import { memo, useCallback, useMemo, useState } from "react";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import classes from "./HamburgerMenu.module.scss";
import { motion } from "framer-motion";
import { MENU_OPTIONS_COLLECTION } from "collections/MenuOptions.collection";
import { useTranslation } from "react-i18next";

const HamburgerMenu = () => {

  const { t } = useTranslation();
  const [opened, setOpened] = useState<boolean>(false);

  const [pageVariants, setPageVariants] = useState({
    initial: {
      opacity: 0,
      zIndex: 0
    },
    in: {
      opacity: 0,
      zIndex: 0
    },
    out: {
      opacity: 0,
      zIndex: 0
    },
  });

  const pageTransition = useMemo(
    () => ({
      type: "spring",
      ease: "linear",
      duration: 0.9,
    }),
    []
  );

  const handleClick = useCallback((opened: boolean) => {
    const promise = setTimeout(() => {
      setPageVariants({
        initial: {
          opacity: opened ? 0 : 1,
          zIndex: opened ? 10: 0
        },
        in: {
          opacity: opened ? 1 : 0,
          zIndex: opened ? 10: 0
        },
        out: {
          opacity: opened ? 1 : 0,
          zIndex: opened ? 10: 0
        },
      });
      clearTimeout(promise);
    });
    setOpened(opened);
    if(opened) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, []);

  return (
    <>
      <HamburgerButton opened={opened} onClick={handleClick} />
      <motion.aside
        style={{ height: "100%" }}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
        className={classes["hamburger-menu"]}>
        <ul>
          {MENU_OPTIONS_COLLECTION.map((option) => {
            const { title, href } = option;
            const titleTranslated = t(title as unknown as TemplateStringsArray);
            return (
              <li key={href}>
                <a href={href} title={titleTranslated} onClick={() => handleClick.bind(this)(false)}>
                  {titleTranslated}
                </a>
              </li>
            );
          })}
        </ul>
      </motion.aside>
    </>
  );
};

export default memo(HamburgerMenu);
