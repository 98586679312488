import Introduction from "components/UI/Introduction/Introduction";
import "./App.scss";
import Header from "./components/UI/Header/Header";
import MediaQueryContextProvider from "providers/MediaQueryContextProvider";
import Facts from "components/UI/Facts/Facts";
import Services from "components/UI/Services/Services";
import Technologies from "components/UI/Technologies/Technologies";
import Testimonials from "components/UI/Testimonials/Testimonials";
import ContactUs from "components/UI/ContactUs/ContactUs";
import Footer from "components/UI/Footer/Footer";

function App() {
  return (
    <MediaQueryContextProvider>
      <Header />
      <main>
        <Introduction />
        <Facts/>
        <Services/>
        <Technologies />
        <Testimonials />
        <ContactUs />
      </main>
      <footer>
        <Footer />
      </footer>
    </MediaQueryContextProvider>
  );
}

export default App;
