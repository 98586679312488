import { ReplaySubject } from "rxjs";
import { device } from "./DeviceSizes";

export type MediaMatchType = 'mobile' | 'tablet' | 'desktop' | 'desktopLarge';

export class MediaQuery {

  private _isMobile = new ReplaySubject<boolean>();
  private _isTablet = new ReplaySubject<boolean>();
  private _isDesktop = new ReplaySubject<boolean>();
  private _isDesktopLarge = new ReplaySubject<boolean>();
  private _update = new ReplaySubject<MediaMatchType>();
  private _width = new ReplaySubject<number>();

  readonly isMobile$ = this._isMobile.asObservable();
  readonly isTablet$ = this._isTablet.asObservable();
  readonly isDesktop$ = this._isDesktop.asObservable();
  readonly isDesktopLarge$ = this._isDesktopLarge.asObservable();
  readonly update$ = this._update.asObservable();
  readonly withd$ = this._width.asObservable();

  update(): void {
    console.log(`MediaQueryService.update ...`);
    this._isMobile.next(this.checkMobile());
    this._isTablet.next(this.checkTablet());
    this._isDesktop.next(this.checkDesktop());
    this._isDesktopLarge.next(this.checkDesktopLarge());
    this._update.next(this.getMediaQueryType());
    this._width.next(this.getWidth());
  }

  checkMobile(): boolean {
    return window.matchMedia(device.mobile).matches;
  }

  checkTablet(): boolean {
    return window.matchMedia(device.tablet).matches;
  }

  checkDesktop(): boolean {
    return window.matchMedia(device.desktop).matches;
  }

  checkDesktopLarge(): boolean {
    return window.matchMedia(device.desktopLarge).matches;
  }

  getWidth() {
    return window.screen.width;
  }

  getMediaQueryType(): MediaMatchType {
    let type: MediaMatchType = 'mobile';
    if (this.checkDesktopLarge()) {
      type = 'desktopLarge';
    } else if (this.checkDesktop()) {
      type = 'desktop';
    } else if (this.checkTablet()) {
      type = 'tablet';
    }
    return type;
  }
}

