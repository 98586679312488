import whatsapp from 'assets/images/whatsapp.svg';
import classes from './ContactUs.module.scss';
import { useTranslation } from "react-i18next";

const ContactUs = () => {
    const { t } = useTranslation();
    return <a id='contactus' 
               className={classes.whatsapp} 
               href="https://wa.me/15188931271" 
               title='phone' 
               target='_blank'
               rel='noreferrer'>
        <span>{t('home.callToAction')}</span>
        <img src={whatsapp} alt='whatsapp'/>
    </a>
}

export default ContactUs;